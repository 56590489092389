/*
 * Plugins
 * jQuery
 * bootstrap-select      => https://developer.snapappointments.com/bootstrap-select/
 * datepicker            => https://bootstrap-datepicker.readthedocs.io/en/latest/
 * AOS                   => https://github.com/michalsnik/aos
 * basicScroll           => https://github.com/electerious/basicScroll
 * parallax.js           => http://pixelcog.github.io/parallax.js/
 * 沒辦法 reisze 後重算位置 (難用)
 */
import { aos } from '../plugins/aos/aos'
import { handle_basicscroll } from '../plugins/basicscroll/basicscroll'
import 'bootstrap-select'
import 'bootstrap-datepicker'
import { datepicker } from '../plugins/datepicker/datepicker_options'
import { text_typing_animate } from '../plugins/loading'
import 'jquery-parallax.js'
import './../plugins/slick/slick'
import $ from 'jquery'
import _ from 'lodash'

$(function () {
    // * Custom functions
    text_typing_animate()
    detect_spread_page()

    // * Plugin init
    $('select').selectpicker()
    $('.datepicker').datepicker(datepicker.options)
    $('.datepicker input')
        .prop('readonly', 'readonly')
        .css('background-color', 'white')
    if ($('.js-basicScroll').length > 0) handle_basicscroll()
    slick_init()

    // * limit texts
    limit_texts()

    // * Detect current page and addClass to Navbar
    handle_navbarStyle()

    // * EventListener
    $(window).on('resize', _.debounce(handle_navHeight, 50))
    $('.js--scroll_to').on('click', function () {
        detect_scroll_to(this)
    })
})

$(window).on('load', function () {
    handle_navHeight()
    setTimeout(() => {
        let loading = document.querySelector('.loading')
        loading.classList.add('animate__fadeOut')
        aos()
    }, 500)
    setTimeout(() => {
        let loading = document.querySelector('.loading.animate__fadeOut')
        loading.parentNode.removeChild(loading)
    }, 1000)
})

/*
 * Detect Nav Height
 */
function detect_navHeight() {
    return $('#navbar').innerHeight()
}

function append_navHeight(navHeight) {
    $('.js-nav--height').attr('style', 'height:' + navHeight + 'px')
}

function handle_navHeight() {
    let navHeight = detect_navHeight()
    append_navHeight(navHeight)
}

/*
 * Scroll To Target
 * 要加入滑動動畫的話，觸發按鈕須加上 class, scroll-href，目標對象要加 id
 * 1. class => '.js--scroll_to'
 * 2. scroll-href="targetHref" (不需要'#')
 * 3. 要滑過去的對象要記得加上 id="targetHref"
 * 跨頁滑動的話 href 只需要寫頁面，不需要加上 Anchor (#targetHref)
 * 使用 SessionStorage 存取
 */
function scroll_to(target, offset) {
    const animate_time = 500
    target = '#' + target
    $('html, body').animate(
        {
            scrollTop: $(target).offset().top - offset + 2,
        },
        animate_time,
        'swing'
    )
}

// * Detect Scroll to MIDDLE or TOP
function detect_scroll_to(this_el) {
    let target = set_session_href(this_el)
    let height_nav = detect_navHeight()
    let height_target = $('#' + target).innerHeight()
    let height_window_noNav = $(window).innerHeight() - height_nav
    let setMiddle_offset_top =
        (height_window_noNav - height_target) / 2 + height_nav
    if (!$('#' + target).length > 0) return
    if (height_target > height_window_noNav) {
        scroll_to(target, height_nav)
    } else {
        scroll_to(target, setMiddle_offset_top)
    }
    window.sessionStorage.removeItem('scroll-href')
}

// * Detect Cross Scroll
function detect_spread_page() {
    let scroll_href = window.sessionStorage.getItem('scroll-href')
    if ($('#' + scroll_href).length > 0) {
        setTimeout(() => {
            detect_scroll_to(scroll_href)
        }, 1000)
    }
}

// * Set sessionStorage
function set_session_href(this_el) {
    let target = this_el
    if ($(this_el).attr('scroll-href')) {
        window.sessionStorage.setItem(
            'scroll-href',
            $(this_el).attr('scroll-href')
        )
        target = $(this_el).attr('scroll-href')
    }
    return target
}

/*
 * Detect page pathname and add class to Navbar
 * .nav-item 要加上兩個屬性
 * class => js-nav-append
 * nav-href => blade name
 */
function handle_navbarStyle() {
    let pathname = window.location.pathname
    let nav_item = [...document.querySelectorAll('.js-nav-append')]
    for (let i = 0; i < nav_item.length; i++) {
        if (pathname.indexOf(nav_item[i].getAttribute('nav-href')) > 0) {
            nav_item[i].classList.add('active')
        }
    }
}

function limit_texts(targetSelector = '.js-text-limits') {
    if (document.querySelectorAll(targetSelector) !== null) {
        const target_nodes = [...document.querySelectorAll(targetSelector)]
        const limited_nodes = target_nodes.map(node => limit(node.textContent))
        target_nodes.forEach((node, i) => (node.textContent = limited_nodes[i]))
    }

    function limit(original_string = '', wordCount = 50) {
        original_string.splice
        let result = original_string.split('')
        result.splice(wordCount)
        return result.join('') + '...'
    }
}

function slick_init() {
    const slickSetting = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        // touchMove: true,
        // centerMode: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    }
    $('#news_slider').slick(slickSetting)
    $('#spaces_slick').slick(slickSetting)
}
