import { validate } from 'validate.js'
;(function () {
    // * 判斷語系
    const current_lang = document
        .getElementsByTagName('html')[0]
        .getAttribute('lang')

    const lang = {
        'zh-TW': {
            contact_name: {
                presence: {
                    message: '^姓名 是必填的欄位',
                },
            },
            contact_gender: {
                presence: {
                    message: '^性別 是必填的欄位',
                },
            },
            contact_phone: {
                presence: {
                    message: '^連絡電話 是必填的欄位',
                },
                format: {
                    message: '^連絡電話 只能包含數字，並請確認內容是否正確',
                },
            },
            contact_email: {
                presence: {
                    message: '^e-mail 是必填的欄位',
                },
                format: {
                    message: '^e-mail 須為正確格式',
                },
            },
            contact_type: {
                presence: {
                    message: '^聯絡事宜 是必填的欄位',
                },
            },
            contact_reservation_date: {
                presence: {
                    message: '^預約日期 是必填的欄位',
                },
            },
            contact_message: {
                presence: {
                    message: '^留言內容 是必填的欄位',
                },
            },
        },
        en: {
            contact_name: {
                presence: {
                    message: '^Name is required.',
                },
            },
            contact_gender: {
                presence: {
                    message: '^Gender is required.',
                },
            },
            contact_phone: {
                presence: {
                    message: '^Phone number is required.',
                },
                format: {
                    message:
                        '^Phone number can only include numbers, please confirm if the contents are correct.',
                },
            },
            contact_email: {
                presence: {
                    message: '^e-mail is required.',
                },
                format: {
                    message: '^e-mail needs to be in the correct format',
                },
            },
            contact_type: {
                presence: {
                    message: '^Reason for inquiry is required.',
                },
            },
            contact_reservation_date: {
                presence: {
                    message:
                        '^Date you prefer to make an appointment is required.',
                },
            },
            contact_message: {
                presence: {
                    message: '^Your message is required.',
                },
            },
        },
    }
    var constraints = {
        contact_name: {
            presence: {
                message: lang[current_lang].contact_name.presence.message,
            },
        },
        contact_gender: {
            presence: {
                message: lang[current_lang].contact_gender.presence.message,
            },
        },
        contact_phone: {
            presence: {
                message: lang[current_lang].contact_phone.presence.message,
            },
            format: {
                pattern: '^[0-9]{10}$',
                message: lang[current_lang].contact_phone.format.message,
            },
        },
        contact_email: {
            presence: {
                message: lang[current_lang].contact_email.presence.message,
            },
            format: {
                pattern:
                    '^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$',
                message: lang[current_lang].contact_email.format.message,
            },
        },
        contact_type: {
            presence: {
                message: lang[current_lang].contact_type.presence.message,
            },
        },
        contact_reservation_date: {
            presence: {
                message:
                    lang[current_lang].contact_reservation_date.presence
                        .message,
            },
        },
        contact_message: {
            presence: {
                message: lang[current_lang].contact_message.presence.message,
            },
        },
    }

    var form = document.querySelector('form#contact_form')
    if (form) {
        form.addEventListener('submit', function (ev) {
            ev.preventDefault()
            handleFormSubmit(form)
        })
    }

    // 監聽 input 值改變的狀況
    var inputs = document.querySelectorAll('input, textarea, select')
    if (inputs) {
        for (var i = 0; i < inputs.length; ++i) {
            if (inputs.item(i).nodeName === 'TEXTAREA') {
                // * textarea => blur
                inputs.item(i).addEventListener('blur', function (ev) {
                    var errors = validate(form, constraints) || {}
                    showErrorsForInput(this, errors[this.name])
                })
            } else {
                // * input, select => change
                inputs.item(i).addEventListener('change', function (ev) {
                    var errors = validate(form, constraints) || {}
                    showErrorsForInput(this, errors[this.name])
                })
            }
        }
    }

    // 重設表單
    function reset_form() {
        if (inputs) {
            for (var i = 0; i < inputs.length; ++i) {
                inputs[i].value = ''
            }
        }
        document.querySelector('.filter-option-inner-inner').textContent = ''
    }

    // 沒有錯誤就顯示成功傳送
    function handleFormSubmit(form, input) {
        showSuccess(form)
        // var errors = validate(form, constraints)
        // showErrors(form, errors || {})
        // if (!errors) {
        //     showSuccess(form)
        // }
    }

    function showErrors(form, errors) {
        _.each(
            form.querySelectorAll('input[name], select[name], textarea[name]'),
            function (input) {
                showErrorsForInput(input, errors && errors[input.name])
            }
        )
    }

    // Shows the errors for a specific input
    function showErrorsForInput(input, errors) {
        var formGroup = closestParent(input.parentNode, 'form-group'),
            messages = formGroup.querySelector('.messages'),
            border = formGroup.querySelectorAll('.js-formValidate--border')
        resetFormGroup(formGroup)
        if (errors) {
            formGroup.classList.add('has-error')
            _.each(errors, function (error) {
                addError(messages, error, border)
            })
        } else {
            formGroup.classList.add('has-success')
        }
    }

    function closestParent(child, className) {
        if (!child || child == document) {
            return null
        }
        if (child.classList.contains(className)) {
            return child
        } else {
            return closestParent(child.parentNode, className)
        }
    }

    function resetFormGroup(formGroup) {
        formGroup.classList.remove('has-error')
        formGroup.classList.remove('has-success')
        // * remove messages
        _.each(formGroup.querySelectorAll('.help-block.error'), function (el) {
            el.parentNode.removeChild(el)
        })
        // * remove border style class
        _.each(formGroup.querySelectorAll('.error-style'), function (el) {
            el.classList.remove('border-danger')
        })
    }

    function addError(messages, error, border) {
        var block = document.createElement('p')
        // * message
        block.classList.add('help-block')
        block.classList.add('error')
        block.innerText = error
        messages.appendChild(block)
        // * border
        for (var i = 0; i < border.length; i++) {
            border[i].classList.add('error-style')
            border[i].classList.add('border-danger')
        }
    }
    function showSuccess(form) {
        // TODO SUCCESS AJAX
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
        })

        var request = $('form#contact_form').serialize()

        $.ajax({
            url: window.location.href + '/store',
            type: 'post',
            data: request,
            success: function (res) {
                if (window.gtag)
                    gtag('event', 'contact_click', {
                        event_category: 'contact',
                    })
                if (res.success) {
                    $('#successModal').modal('show')
                    reset_form()
                } else {
                    console.error('[contactForm error]:', res.message)
                    $('#errModal').modal('show')
                }
            },
            error(err) {
                console.error('[contactForm error]:', err)
                $('#errModal').modal('show')
            },
        })
    }
})()
