// require('./datepicker_zh-tw');
import { zh_tw } from './datepicker_zh-tw'

const datepicker = {
    language: zh_tw,
    options: {
        format: 'yyyy-mm-dd',
        defaultDate: new Date(),
        startDate: 'today',
        minDate: '+1d',
        language: 'zh-TW',
        todayHighlight: true,
        // * multidate: {boolean, number}
        multidate: false,
        templates: {
            leftArrow: '<i class="fas fa-angle-left"></i>',
            rightArrow: '<i class="fas fa-angle-right"></i>',
        },
        disableTouchKeyboard: true,
    },
}
const datepicker_admin = {
    language: zh_tw,
    options: {
        format: 'yyyy-mm-dd',
        // defaultDate:new Date(),
        startDate: 'today',
        // minDate: '+1d',
        language: 'zh-TW',
        todayHighlight: true,
        showDuration: true,
        // * multidate: {boolean, number}
        multidate: false,
        templates: {
            leftArrow: '<i class="fas fa-angle-left"></i>',
            rightArrow: '<i class="fas fa-angle-right"></i>',
        },
        disableTouchKeyboard: true,
    },
}
export { datepicker, datepicker_admin }
