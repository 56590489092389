// const basicScroll = require('basicscroll');
import * as basicScroll from 'basicscroll'

export function handle_basicscroll() {
    let basicScroll_el = document.querySelectorAll('.js-basicScroll')
    for (let i = 0; i < basicScroll_el.length; i++) {
        let modifier_Y = 0
        if (basicScroll_el[i].getAttribute('data-y'))
            modifier_Y = basicScroll_el[i].getAttribute('data-y')
        let from = basicScroll_el[i].getAttribute('data-from')
            ? basicScroll_el[i].getAttribute('data-from')
            : 'top-top'
        basicScroll
            .create({
                elem: basicScroll_el[i],
                from: from,
                to: 'bottom-top',
                direct: basicScroll_el[i],
                props: {
                    '--translateY': {
                        from: `${-modifier_Y}px`,
                        to: `${modifier_Y}px`,
                        time: 'cubicIn',
                    },
                },
            })
            .start()
    }
}
